import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { MenuGroup, MenuItem, SubNav } from "@ax/components";
import { sitesActions } from "@ax/containers/Sites";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { INITIAL_TEMPLATE } from "@ax/containers/PageEditor/constants";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { IQueryValue, IStructuredData } from "@ax/types";
import { usePermission } from "@ax/hooks";

import { getFilters } from "./utils";

import * as S from "./style";

const ContentFilters = (props: IProps): JSX.Element => {
  const {
    setContentFilter,
    current,
    dynamicValues,
    isAllowedToCreate,
    typeFilters,
    addTemplate,
    setSelectedStructuredData,
    resetFilter,
    setFilter,
    addNew,
  } = props;

  const privatePermissions = [
    "content.createPrivateContentTypes",
    "content.deletePrivateContentTypes",
    "content.duplicatePrivateContentTypes",
    "content.publishUnpublishPrivateContentTypes",
  ];
  const isAllowedToAccessPrivate = usePermission(privatePermissions);

  const type = typeFilters[0].value;
  const filters = getFilters(dynamicValues);

  const handleClickData = (value: string, fromPage = false, firstTemplate: string | null = null) => {
    const hasTemplate = fromPage && firstTemplate;

    resetFilter();
    setContentFilter(value);
    setSelectedStructuredData(value, "site");
    if (hasTemplate) {
      addTemplate(firstTemplate);
    } else {
      addTemplate(INITIAL_TEMPLATE);
    }
  };

  const handleClickBase = (value: string) => {
    resetFilter();
    setFilter("type", [{ value, label: value }]);
    setContentFilter(value);
    setSelectedStructuredData(value, "site");
  };

  return (
    <SubNav>
      <S.Wrapper>
        {filters.map((filter) => {
          const isSelected = type === "all" ? filter.value === current : filter.value === type;
          const selectedClass = isSelected ? "selected" : "";

          const handleClick = (value: string, fromPage = false, firstTemplate: string | null = null) =>
            filter.value === "unique" ? handleClickBase(value) : handleClickData(value, fromPage, firstTemplate);

          return (
            <React.Fragment key={filter.value}>
              {!filter.items ? (
                <MenuItem onClick={() => handleClickBase(filter.value)} className={selectedClass}>
                  <NavLink to="#">
                    <S.Link active={isSelected}>{filter.label}</S.Link>
                  </NavLink>
                </MenuItem>
              ) : (
                <MenuGroup
                  filter={filter}
                  current={current}
                  onClick={handleClick}
                  addNew={addNew}
                  isAllowedToCreate={isAllowedToCreate}
                  isAllowedToAccessPrivate={isAllowedToAccessPrivate}
                />
              )}
            </React.Fragment>
          );
        })}
      </S.Wrapper>
    </SubNav>
  );
};

interface IProps {
  current: string | undefined;
  dynamicValues: IStructuredData[];
  isAllowedToCreate: boolean;
  typeFilters: IQueryValue[];
  setContentFilter(filter: string | null): void;
  addTemplate(template: string): void;
  setSelectedStructuredData(id: string, scope: string): void;
  resetFilter(): void;
  setFilter: (pointer: string, filter: IQueryValue[]) => void;
  addNew: () => void;
}

const mapDispatchToProps = {
  setContentFilter: sitesActions.setFilter,
  addTemplate: pageEditorActions.addTemplate,
  setSelectedStructuredData: structuredDataActions.setSelectedStructuredData,
};

export default connect(null, mapDispatchToProps)(ContentFilters);
