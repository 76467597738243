import styled from "styled-components";

const Item = styled.li`
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  margin-top: ${(p) => p.theme.spacing.xxs};
`;

const NavLink = styled.div<{ selected: boolean }>`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  align-items: center;
  height: 35px;
  cursor: pointer;
  border-radius: ${(p) => p.theme.radii.s};
  background-color: ${(p) => (p.selected ? p.theme.color.overlayPressedPrimary : "transparent")};
  padding: ${(p) => `0 ${p.theme.spacing.xs}`};
  color: ${(p) => (p.selected ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiM};
`;

const Arrow = styled.div`
  margin-left: auto;
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
`;

const Dropdown = styled.ul<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? "flex" : "none")};
  transition: all 0.5s ease-in-out;
  opacity: ${(p) => (p.isOpen ? "1" : "0")};
  flex-direction: column;
  border-left: 1px solid ${(p) => p.theme.color.uiLine};
  padding-left: ${(p) => p.theme.spacing.xxs};
  margin-left: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const Link = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-left: ${(p) => p.theme.spacing.xs};
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const Text = styled.div<{ active: boolean; disabled: boolean }>`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) =>
    p.disabled
      ? p.theme.color.interactiveDisabled
      : p.active
        ? p.theme.color.textHighEmphasis
        : p.theme.color.textMediumEmphasis};
`;

export { Item, NavLink, Title, Arrow, Dropdown, Link, Icon, Text };
